<template>
  <b-modal
    id="modal-subscription"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <header
        class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2"
      >
        <b-row>
          <b-col cols="3" class="d-flex align-items-center">
            <div style="cursor: pointer" @click="directPage">
              <feather-icon
                size="24"
                class="text-dark font-weight-bolder"
                icon="ArrowLeftIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1"
                >Kembali</span
              >
            </div>
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-center align-items-center"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              {{
                editUuid !== null
                  ? "Detail Berlangganan"
                  : "Tambah Berlangganan"
              }}
            </h1>
          </b-col>
          <b-col cols="3" class="text-right">
            <b-button class="px-3" @click="updateItem()"> Simpan </b-button>
          </b-col>
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col cols="12" md="5">
            <div class="mb-2">
              <label for="v-nama"
                >Nama Berlangganan <span class="text-danger">*</span></label
              >
              <b-form-group>
                <b-form-input
                  id="v-nama"
                  v-model="formPayload.renewal_name"
                  type="text"
                  placeholder="Nama Berlangganan"
                  class="custom__input"
                />
              </b-form-group>
            </div>
            <div class="mb-2">
              <label for="v-description"
                >Deskripsi Berlangganan
                <span class="text-danger">*</span></label
              >
              <b-form-group>
                <b-form-input
                  id="v-description"
                  v-model="formPayload.renewal_description"
                  type="text"
                  placeholder="Deskripsi"
                  class="custom__input"
                />
              </b-form-group>
            </div>
            <div class="mb-2">
              <label for="v-months"
                >Masa Berlangganan (Bulan)
                <span class="text-danger">*</span></label
              >
              <b-form-group>
                <b-form-input
                  id="v-months"
                  v-model="formPayload.renewal_duration_months"
                  type="text"
                  placeholder="Masa Berlangganan"
                  class="custom__input"
                />
              </b-form-group>
            </div>
            <div class="mb-2 custom__form--input">
              <b-form-group label-for="v-harga">
                <label for="">Harga <span class="text-danger">*</span></label>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text class="w-100">
                    <span class="text-dark fw-bold-600 size14">Rp</span>
                    <money
                      id="v-harga"
                      v-model="formPayload.price"
                      v-bind="money"
                      placeholder="Harga"
                      class="custom__input border-rad-top-left-0 border-rad-bottom-left-0 border-rad-top-right-8 border-rad-bottom-right-8 w-100"
                    />
                  </b-input-group-prepend>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="mb-2 custom__form--input">
              <b-form-group label-for="v-harga-jual">
                <label for=""
                  >Harga Jual <span class="text-danger">*</span></label
                >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text class="w-100">
                    <span class="text-dark fw-bold-600 size14">Rp</span>
                    <money
                      id="v-harga-jual"
                      v-model="formPayload.sell_price"
                      v-bind="money"
                      placeholder="Harga"
                      class="custom__input border-rad-top-left-0 border-rad-bottom-left-0 border-rad-top-right-8 border-rad-bottom-right-8 w-100"
                    />
                  </b-input-group-prepend>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="mb-2">
              <label for="v-discount"
                >Diskon (%)
                <span class="text-danger">*</span></label
              >
              <b-form-group>
                <b-form-input
                  id="v-discount"
                  v-model="formPayload.discount_percent"
                  type="text"
                  placeholder="Diskon"
                  class="custom__input"
                />
              </b-form-group>
            </div>

            <!-- <div class="mb-2">
                <label for="v-renewalEnd">Tanggal Berakhir</label>
                <b-form-group>
                  <b-form-datepicker
                    id="v-renewalEnd"
                    v-model="formPayload.renewal_end_date"
                    class="custom__input"
                    placeholder="Tanggal Berakhir"
                    locale="id"
                  ></b-form-datepicker>
                </b-form-group>
              </div>
  
              <div class="mb-2" v-if="editUuid !== null">
                <label>Sisa Waktu Berlangganan</label>
                <b-col cols="12" class="custom__input mb-2 pt-1">
                  {{ formPayload.remaining_renewal_days }} Hari
                </b-col>
              </div> -->
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import vSelect from "vue-select";

import BaseInput from "@/components/BaseInput.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BContainer,
    BaseInput,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormSelect,
    BFormSelectOption,
    BFormDatepicker,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "",
    },
    getData: {
      type: Function,
    },
    result: {
      type: Object,
    },
    // getDetail: {
    //   type: Function,
    // },
    detailResult: {
      type: Object,
    },
    editItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    editId: {
      type: "",
    },
    cleanUpForm: {
      type: Function,
    },
  },
  watch: {
    editId(value) {
      this.editUuid = value;
    },
    formData(value) {
      this.formPayload = value;
    },
    formPayload: {
      handler(value) {
        this.$emit("getPayload", value);
      },
      deep: true,
    },
  },
  data() {
    return {
      editUuid: null,
      money: {
        decimal: "",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
      formPayload: {
        renewal_name: "",
        renewal_description: "",
        renewal_duration_months: "",
        price: "",
        sell_price: "",
        discount_percent: "",
      },
      searchQuery: "",
      countryList: [],
      optionsToko: [],
    };
  },
  mounted() {},
  methods: {
    directPage() {
      //   this.cleanUpForm()
      this.$bvModal.hide("modal-subscription");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

label {
  font-size: 14px;
  font-weight: 600;
  color: #2b324f;
}

.custom__input {
  height: 52px;
  background: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
  color: #2b324f;
  font-weight: 600;
  font-size: 14px;
}

.input-group {
  input {
    background: $light--background;
    width: 100%;

    &:focus-visible,
    &:focus {
      outline: none !important;
    }
  }

  .input-group-append {
    .input-group-text {
      width: 100%;
      height: 53px;
      border: 1px solid $light--background;
      background-color: $light--background;
      color: $light--description;
      border-radius: 8px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .input-group-prepend {
    .input-group-text {
      width: 100%;
      height: 53px;
      border: 1px solid $light--background;
      background-color: $light--background;
      color: $light--description;
      border-radius: 8px;
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }
}
</style>
