<template>
  <div class="section section__subscription">
    <vue-good-table
      v-if="result.data"
      max-height="100vh"
      :columns="columns"
      :rows="result.data"
      :fixed-header="false"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <div class="d-flex">
              <!-- <b-button
                  class="mr-1 px-1 border-0 bg-pink"
                  @click="deleteData(props.row.id)"
                >
                  <feather-icon
                    size="18"
                    class="text-danger"
                    icon="Trash2Icon"
                  />
                </b-button> -->
              <b-button class="bg-white" @click="editItem(props.row.id)">
                Lihat Detail
              </b-button>
              <!-- <b-button class="bg-white" @click="editItem(props.row)">
                  Kelola User
                </b-button> -->
            </div>
          </span>
        </span>

        <span v-else-if="props.column.field === 'renewal_duration_months'">
          <span>
            {{ props.row.renewal_duration_months }} Bulan
          </span>
        </span>

        <span v-else-if="props.column.field === 'price'">
          <span>
            {{ props.row.price | formatAmount }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'sell_price'">
          <span>
            {{ props.row.sell_price | formatAmount }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'discount_percent'">
          <span> {{ props.row.discount_percent }} % </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="result.data"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10', '15', '20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan 1 ke {{ filter.per_page }} dari
          {{ result.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="result"
              :limit="4"
              :align="'right'"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import FilterButton from "@/components/BaseFilter.vue";
import {
  BRow,
  BCol,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
    FilterButton,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    getData: {
      type: Function,
    },
    getFilter: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    result: {
      type: Object,
    },
    getDetail: {
      type: Function,
    },
    detailResult: {
      type: Object,
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Nama Berlangganan",
          field: "renewal_name",
        },
        {
          label: "Durasi Berlangganan",
          field: "renewal_duration_months",
        },
        {
          label: "Harga",
          field: "price",
        },
        {
          label: "Harga Jual",
          field: "sell_price",
        },
        {
          label: "Diskon",
          field: "discount_percent",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      items: [],
      search: "",
      filter: {
        search: "",
        sort_type: "desc",
        per_page: 10,
      },
      isLoadingExport: false,
    };
  },
  watch: {
    filter: {
      handler(value) {
        this.$emit("filterBase", value);
      },
      deep: true,
    },
  },
  methods: {
    // goToDetail(uuid) {
    //   this.getDetail(uuid);
    //   this.$bvModal.show("modal-cabang");
    // },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.section__subscription {
  .vgt-responsive {
    height: calc(100vh - 235px) !important;
    background-color: #fff;
  }
}
</style>
